import type { IRequestOptions, ApiRequestOptions } from 'o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts';
import type { TruncateIndexDBObjectStoreMode } from 'o365.pwa.types.ts';
import type { Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

declare module 'o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOnlineSyncRequestOptions.d.ts' {
    interface IOnlineSyncBaseOptions {
        requestGuid: string;
        dataObjectId: string;
        appId: string;
        viewName: string;
        originalViewName: string;
        offlineDataType: string;
        truncateMode: TruncateIndexDBObjectStoreMode;
        objectStoreIdOverride: string;
        deviceRef?: string;
    }

    export class ApiPwaOnlineSyncOptions implements IRequestOptions {
        readonly requestGuid: string;
        readonly appId: string;
        readonly viewName: string;
        readonly originalViewName: string;
        readonly offlineDataType: string;
        readonly dataObjectId: string;
        readonly objectStoreIdOverride: string;
        readonly truncateMode: TruncateIndexDBObjectStoreMode;
        readonly deviceRef?: string;

        constructor(options: IOnlineSyncBaseOptions);

        static fromRequest(request: Request): Promise<ApiRequestOptions<ApiPwaOnlineSyncOptions>>;
    }
}
